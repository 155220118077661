import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { AuthService } from './auth.service';
import { ApplicationModel, StudentProfileModel } from '@upt/erasmus/types';
import { BehaviorSubject } from 'rxjs';
import { CfgService } from './cfg.service';
import { ReferencesHelperService } from './references-helper.service';
import { environment } from '@upt/erasmus/shared';
const baseUrl = `${environment.apiUrl}/api/moderator/`;

@Injectable()
export class DashboardService {
    public students: BehaviorSubject<StudentProfileModel[]> = new BehaviorSubject<StudentProfileModel[]>([]);
    private studentStore: StudentProfileModel[];
    public applications: BehaviorSubject<ApplicationModel[]> = new BehaviorSubject<ApplicationModel[]>([]);
    private applicationStore: ApplicationModel[];
    constructor(
        private http: HttpClient,
        private auth: AuthService,
        private cfg: CfgService,
        private refService: ReferencesHelperService
    ) { }

    public get appStatuses() { return this.cfg.appStatuses }

    public async getDashboard(): Promise<any> {
        const url = `${baseUrl}getdashboard/${this.auth.appType}`;
        try {
            const res = await this.http.get<any>(url, await this.auth.getOptions(true)).toPromise();
            return res;
        } catch {
            // TODO: treat exception
            return null;
        }
    }

    public async getAllStudents(): Promise<StudentProfileModel[]> {
        this.studentStore = [];
        this.students.next(this.studentStore);
        const url = `${baseUrl}GetAllStudents`;

        try {
            const result = await this.http.get<any>(url, await this.auth.getOptions(true)).toPromise();
            this.studentStore = result;
            this.students.next(this.studentStore);
            return result;
        } catch {
            // TODO: treat exception
            return [];
        }
    }


    public async getMobilityMapApplications(mobilityMapId): Promise<any> {
        const url = `${baseUrl}getMobilityMapApplications/${mobilityMapId}`;
        const result: any = await this.http.get<any>(url, await this.auth.getOptions(true)).toPromise();
        await this.populateAndPatch(result);
        return result
    }

    public async countAllApplications(type: string, search: string): Promise<number> {
        let url = `${baseUrl}CountAllApplications/${type}?search=${search}`;
        if (this.auth.appType == "ErasmusForStaff") {
            url = `${baseUrl}CountAllApplicationsStaff/${type}?search=${search}`;
        }
        try {
            const result = await this.http.get<number>(url, await this.auth.getOptions(true)).toPromise();
            return result;
        } catch {
            // TODO: treat exception
            return 0;
        }
    }

    public async exportToExcelApplications(type: string): Promise<{ filePath: string }> {
        let url = `${baseUrl}ExportToExcelApplications/${type}`;
        if (this.auth.appType == "ErasmusForStaff") {
            url = `${baseUrl}ExportToExcelApplicationsStaff/${type}`;
        }
        try {
            const result = await this.http.get<{ filePath: string }>(url, await this.auth.getOptions(true)).toPromise();
            return result;
        } catch {
            // TODO: treat exception
            return null;
        }
    }


    public async getAllApplications(type: string, page: number, size: number, appId: string, searchString: string): Promise<any> {
        this.applicationStore = [];
        this.applications.next(this.applicationStore);
        let url = `${baseUrl}GetAllApplications/${type}?page=${page}&size=${size}&appId=${appId}&search=${searchString}`;
        if (this.auth.appType == "ErasmusForStaff") {
            url = `${baseUrl}GetAllApplicationsStaff/${type}?page=${page}&size=${size}&search=${searchString}`;
        }
        try {
            const result:any = await this.http.get(url, await this.auth.getOptions(true)).toPromise();
            let apps = result.apps ? result.apps : result
            await this.populateAndPatch(apps);
            this.applicationStore = apps;
            this.applications.next(this.applicationStore);
            return result;
        } catch {
            // TODO: treat exception
            return [];
        }
    }

    public async getAllApplicationsWithContestations(type: string): Promise<ApplicationModel[]> {
        this.applicationStore = [];
        this.applications.next(this.applicationStore);
        let url = `${baseUrl}GetAllApplicationsWithContestations/${type}`;
        if (this.auth.appType == "ErasmusForStaff") {
            url = `${baseUrl}GetAllApplicationsStaffWithContestations/${type}`;
        }
        try {
            const result = await this.http.get<ApplicationModel[]>(url, await this.auth.getOptions(true)).toPromise();
            await this.populateAndPatch(result);
            this.applicationStore = result;
            this.applications.next(this.applicationStore);
            return result;
        } catch {
            // TODO: treat exception
            return [];
        }
    }

    public async changeStatus(applicationId: string, changeModel: {
        newStatus: string,
        oldStatus: string,
        message: string
    }): Promise<void> {
        let url = `${baseUrl}ChangeApplicationStatus/${applicationId}/${this.auth.appType}`;

        if (this.auth.appType == "ErasmusForStaff") {
            url = `${baseUrl}ChangeApplicationStatusStaff/${applicationId}`
        }

        await this.http.post(url, changeModel, await this.auth.getOptions(true)).toPromise();
    }

    public async downloadArchive(applicationId: string): Promise<{ filePath: string }> {
        let url = `${baseUrl}DownloadArchive/${applicationId}`;

        try {
            const result = await this.http.get<{ filePath: string }>(url, await this.auth.getOptions(true)).toPromise();
            return result;
        } catch {
            // TODO: treat exception
            return null;
        }
    }

    public getStatusName(status: string): string {
        return this.appStatuses.find(x => x.Value === status).Name;
    }

    protected async populateAndPatch(data: ApplicationModel[]): Promise<void> {
        for (const app of data) {
            if (typeof app.submittedDate === 'string' && app.submittedDate) {
                if (app.submittedDate === '0001-01-01T00:00:00') {
                    app.submittedDate = undefined;
                } else {
                    app.submittedDate = new Date(app.submittedDate);
                }
            }
            const statusObj = this.appStatuses.find(s => s.Value === app.status);
            app.statusName = statusObj ? statusObj.Name : 'Invalid status';
        }
        this.refService.populateReferences(data);
    }

    public getApplicationStatus(): any[] {
        return [
            { name: 'None', tooltip: 'Status unknown' },
            { name: 'WorkInProgress', tooltip: 'The application is in the process of being completed. Student not submitted yet.' },
            { name: 'Submitted', tooltip: 'The application is completed by the student and was submitted.' },
            { name: 'Processing', tooltip: 'The application is in the process of verification.' },
            { name: 'ReviewNeeded', tooltip: 'The application is not complete (some documents or information are missing).' },
            { name: 'Approved', tooltip: 'The application was approved.' },
            { name: 'Rejected', tooltip: 'The application was rejected.' },
            { name: 'Canceled', tooltip: 'The application was canceled by student or by a moderator.' },
        ];
    }
}
