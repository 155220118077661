import { Component } from '@angular/core';
import { AuthService } from '@upt/erasmus/shared';

@Component({
  selector: 'upt-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  public isLoggedIn: boolean;

  constructor(public auth: AuthService) {
    this.authStatusChanged(this.auth.authState).then(() => { });
    auth.authStateChanged.subscribe(async (status) => {
      await this.authStatusChanged(status);
    });
  }

  public async authStatusChanged(status) {
    if (status === false) {
      this.isLoggedIn = false;
      return;
    }

    this.isLoggedIn = true;
  }
}
