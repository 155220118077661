<div class="mat-elevation-z6 landing-container">
    <div class="row">
        <div class="col-lg-8 padding-16 left-container">
            <div class="p-5 mt-5 mt-lg-0">
                <div class="logo-container">
                    <img src="/assets/images/erasmus.png">
                    <img class="right" style="margin-top:20px" src="/assets/images/upt.jpg">
                </div>
                <div class="mat-headline margin-0"><strong>Erasmus+</strong></div>
                <div class="mat-subheading-2 margin-bottom-16"><strong>{{'UPT' | translate}}</strong></div>
                <div class="mat-body-2 padding-topBottom-8">
                    <div [innerHTML]="'LandingPageDesc' | translate"></div>
                </div>
                <div class="my-4">
                    <button mat-raised-button color="primary" (click)="openLoginModal()">{{'Login' | translate}}</button>
                </div>
            </div>
        </div>
        <div class="col-lg-4 d-none d-sm-none d-md-none d-lg-block poli-img"></div>
    </div>
</div>

<upt-login></upt-login>

<p-dialog *ngIf="displayAppChooserDialog" header="Selectează aplicația" [modal]="true" [draggable]="false"
    [resizable]="false" [closable]="false" [(visible)]="displayAppChooserDialog">
    <div class="mb-3 flex">
        <mat-card class="mr-1 cursor-pointer app-card" [class.active]="appType == 'ErasmusForStudents'"
            (click)="this.appType = 'ErasmusForStudents'">
            <h2 class="text-center">{{'ErasmusForStudents' | translate}}</h2>
        </mat-card>
        <mat-card class="ml-1 cursor-pointer app-card" [class.active]="appType == 'ErasmusForStaff'"
            (click)="this.appType = 'ErasmusForStaff'">
            <h2 class="text-center"> {{'ErasmusForStaff' | translate}} </h2>
        </mat-card>
    </div>
    <div mat-dialog-actions class="d-flex flex-column mt-2">
        <div class="d-flex justify-content-center">
            <submit-button (click)="setAppType()" [text]="'GoNext' | translate" [disabled]="!appType">
            </submit-button>
        </div>
    </div>
</p-dialog>

<p-dialog *ngIf="displayGDPRDialog" header="NOTĂ DE INFORMARE PRIVIND PRELUCRAREA DATELOR CU CARACTER PERSONAL"
    [modal]="true" [draggable]="false" [resizable]="false" [closable]="false" [(visible)]="displayGDPRDialog">
    <div class="my-2 grid" style="max-width: 800px;">
        <p>
            &nbsp;&nbsp; În conformitate cu prevederile Regulamentului (UE) 2016/679 privind protecția persoanelor
            fizice în ceea ce privește prelucrarea datelor cu caracter personal și privind libera circulație a acestor
            date Universitatea Politehnica Timișoara prelucrează în condiții de siguranță datele personale
            furnizate.
        </p>
        <p>
            &nbsp;&nbsp;
            Categoriile de date cu caracter personal ce sunt supuse prelucrării fără a avea caracter
            exhaustiv: nume, prenume, CNP, seria și nr. CI/Pașaport, data și locul nașterii, cetățenia, semnătura,
            datele consemnate în actele de stare civilă, domiciliul, profesie, loc de muncă, formare profesională,
            situație familială, situația militară, asigurări de sănătate și sociale, date/conturi bancare, situația
            medicală, informații cu privire la studiile efectuate/absolvite, respectiv adresa de email, număr de
            telefon în vederea îmbunătățirii procesului de comunicare.
        </p>
        <p>
            &nbsp;&nbsp;
            Datele sunt prelucrate în scopul încheierii și executării contractelor și raporturilor juridice
            specifice procesului de muncă, pentru activitățile necesare în vederea îndeplinirii scopului pentru care
            UPT a fost creată - educație și cultură, fiind folosite și pentru analize, prelucrări statistice și
            arhivare,
            conform prevederilor legale.
        </p>
        <p *ngIf="session">
            &nbsp;&nbsp;
            Subsemnatul/a <b>{{session?.student?.firstName}} {{session?.student?.lastName}}</b> am luat la cunoştinţă că
            Universitatea
            Politehnica Timișoara va prelucra, prin mijloace legale, datele
            mele cu caracter personal, pentru îndeplinirea obligațiilor legale și contractuale necesare desfăşurării
            mobilităţilor studenţeşti prin programele universităţii, în conformitate cu Regulamentul (UE) 2016/679
            privind protecţia persoanelor fizice în ceea ce priveşte prelucrarea datelor cu caracter personal şi
            privind libera circulaţie a acestor date.
        </p>
        <p>
            &nbsp;&nbsp;Acest site folosește cookies. Continuarea navigării reprezintă acordul dvs. cu privire la utilizarea acestora.
        </p>
    </div>
    <div mat-dialog-actions class="d-flex flex-column mt-2">
        <div class="d-flex justify-content-center">
            <mat-checkbox [(ngModel)]="gdprAgree" color="primary" class="my-auto mr-2"></mat-checkbox>
            <submit-button (click)="setGdpr()" [text]="'GoNext' | translate" [disabled]="!gdprAgree">
            </submit-button>
        </div>
    </div>
</p-dialog>